@import "../variables";


.mainHeader {
    position:relative;
    background-color: $black;
    top:0;
    left:0;
    .wrapper{
        .navContainer {
            .menuIcon {
                color: $white;
                &:hover,
                &:focus {
                    color: $blue;
                }
            }
            
            .siteNav {
                color: $white;
                a {
                    color: $white;
                }
    
                &:hover,
                &:focus {
                    background-color: $white;
    
                    a {
                        color: $black;
                    }
                }
            }
        }

    }
}

#myVideo {
    height: 100vh;
    position: absolute;
    object-fit: cover;
    width: 100%;
    opacity: 0.75;
}

.mainHeader{
    height:100%;
    width:100%;
    position: relative;
    .wrapper{
        display: flex;
        flex-direction: column;
        height:100vh;
        justify-content: space-between;
        align-items:center;
        position:relative;
    }
    
    p,h1,h2{
        color:$white;
        font-family: $textfontIBM;
        text-align: center; 
    }
}

.title{
    width:80%;    
    text-align: center;
    h1{
        text-transform: capitalize;
        font-size: 4rem;
        margin-bottom: 10px;
    }
    p{
        width: 75%;
        font-size:1.5rem;
        margin:0 auto;
        font-weight: 400;
    }
}


@media(max-width: 950px){
   
    .title{
        margin-top: 175px;
        width:90%;
        h1{
            font-size: 2.75rem;
        }
        h2{
            font-size: 1.75rem;
        }
    }

}




