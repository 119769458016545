@import "../variables";

@media(max-width: 960px) {

    .workbookHeader {
        p {
            line-height: 1.25;
        }

        .navContainer {
            background-color: $white;
        }

        .navLinks.active {
            background-color: $white;
        }
    }
}

//end of Nav styles

.workbookHeaderContent {
    height: calc(100vh - 90px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../assets/heroImages/workbookHero.png");
    background-size: cover;
    background-position: top;
    padding-left: 40px;
}

.workbookHeaderContainer{
    color: $white;
    font-family: $sublineMontserrat;
    h1{
        font-size: 4rem;
    }
    h3{
        text-transform: capitalize;
        font-size: 3rem;
    }
}

.workbookBanner{
    text-align: center;
    padding:20px 0;
    h3{
        font-size: 3.5rem;
        width:80%;
        margin:0 auto;
    }
}
.businessOffTheGround{
    background-color: $grey;
    text-align: center;
    h2{
        text-transform: uppercase;
        padding:50px 0;
    }
    p{
        color:$white;
        padding-bottom: 40px;
        width:75%;
        margin:0 auto;
    }
    button{
        color:$black;
        width:auto;
        margin-bottom: 40px;
    }
}

.growYourBusinessContainer {
    background-color: $black;
    color:$white;
    padding: 40px 0 80px 0;
    .wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        h3 {
            font-size: 3.5rem;
            padding-bottom: 60px;
            text-align: center;
            .importantText{
            color:$grey;
        }
        
    }
    p{
        font-size: 1.5rem;
    }
        .imgContainer{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 60px;
            img{
                border-radius: 0;
                width:62%;
            }
            div{
                width:38%;
                padding-left: 40px;
                font-family: $sublineMontserrat;
                h4{
                    color:$blue;
                    width:60%;
                    font-size: 2rem;
                    text-transform: uppercase;
                }
                p{
                    width:80%;
                }
            }
            .yourBusinessTextContainer2{
                display:flex;
                flex-direction: column;
                align-items: flex-end;
                text-align: right;
                padding-left: 0;
                padding-right: 40px;
            }
        }
    }
    button{
        margin-top: 40px;
        width:auto;
    }
}

.signUpWorkBook{
    background-color: $white;
    color:$black;
    .wrapper{
       p{
        text-align: center;
        padding-bottom: 40px;
       }
       .disclaimerText{
        text-align: center;
       }
    }
}

.activeQuestionContainer .wrapper{
    padding-top:60px ;
    display:flex;
    justify-content: center;
}

@media(max-width: 600px){

    .workbookPage{
        h3{
            font-size: 2.5rem;
        }
    }
    .workbookHeaderContent{
        padding:0;
        text-align: center;
        align-items: center;
        .workbookHeaderContainer{
            h1{
                font-size: 3rem;
            }
        } .buttonContainer{
            margin:0 auto;
            width:auto;
        }
    }

    .businessOffTheGround p{
        width:90%;
    }

        .growYourBusinessContainer .wrapper .imgContainer:first-of-type{
            flex-direction: column-reverse;
            p{
                padding-bottom: 40px;
            }
        }


    .growYourBusinessContainer .wrapper .imgContainer{
        flex-direction: column;
        img, div{
            width:90%;
            padding:0;
            h4, p{
                text-align: center;
                width:100%;
            }
        }
                .yourBusinessTextContainer2 {
                    padding:0 0 40px 0;
                    
                }
    }

}



