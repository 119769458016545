@import "../variables";

.specialsSection {
    background: $black;
    color: $white;

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
        text-align: center;

        h3 {
            font-weight: bold;
            padding-bottom: 10px;
        }

        h2 {
            font-size: 3.75rem;
            text-transform: uppercase;
            padding: 20px 0;
        }

        button {
            width: auto;
        }
    }
}

.shortLogo {
    background: url("../assets/logos/shortLogo.png");
    height: 40px;
    width: 40px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.aboutSection {
    color: $white;
    background-color: $black;

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        min-height: 100vh;
        height: 100%;

        ul {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 70%;

            li {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                padding: 20px 0;

                .shortLogo {
                    height: 50px;
                    width: 10%;
                    margin-right: 10px;
                }

                p {
                    padding: 0;
                    margin: 0;
                    width: 90%;
                    font-weight: 400;
                    font-size: 1.2rem;
                }
            }
        }
    }
}

.aboutHeading {
    display: flex;
    width: 60%;
    align-items: center;
    margin-bottom: 40px;
    padding-top: 10px;

    .logoName {
        width: 40%;
        height: 4rem;
    }

    h3 {
        width: 70%;
        text-transform: lowercase;
        font-weight: 600;
    }
}

.bookACall {
    .wrapper div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px 0;

        h2 {
            font-size: 3rem;
        }

        p {
            width: 52%;
            text-align: center;
            padding: 10px 0 40px 0;
        }

        button {
            width: auto;
        }
    }
}

.discoverSection {
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding-top: 100px;
        min-height: 100vh;
    }
}

.discoverContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 80px;
    padding: 0 15px;

    .flexContainer {
        display: flex;
        width: 100%;
        justify-content: space-between;
        position: relative;
    }
}

.imageContainer {
    border-radius: 40px;
    background-color: $blue;
    padding: 20px 30px 50px 30px;
    color: $white;
    width: calc((100% / 3) + 10px);
}

img {
    max-width: 100%;
    border-radius: 20px;
}

.imageContainer2 {
    background-color: $black;
    position: absolute;
    left: calc((100% / 3) - 5px);
    top: -10%;
    z-index: 10;
    width: calc((100% / 3) + 10px);
}

.imageContainer3 {
    background-color: $grey;
}

.signUpText {
    button {
        width: auto;
    }
    h3{
        width:75%;
    }
    p {
        margin: 5px 0 10px 0;
        width: 60%;
    }

}

h3 {
    font-family: $headlineIBM;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 2rem;
}

.h3Important {
    font-family: $sublineIBM;
    font-weight: 700;
}

.textContainer {
    h4 {
        font-weight: 300;
        text-transform: uppercase;
        font-size: 1.5rem;
        margin: 10px 0 0 0;
        padding: 0;
    }

    p {
        font-size: 1.2rem;
        font-weight: 400;
    }
}

.facilityFeaturesSection{
    background-color: $black;
    color:$white;
    height:100%;
    .wrapper{
        width:80%;
        h2 {
                width: 650px;
                padding: 20px 0;
                line-height: 1.5;
                font-size: 3rem;
            }
            p{
                width: 90%;
            }
            .facilityFeatureList {
                padding: 20px 0;
                li {
                    display: flex;
                    align-items: center;
                    padding-bottom: 10px;
        
                    .graphic {
                        width: 6rem;
                        margin-right: 20px;
                    }
        
                    h3 {
                        text-transform: capitalize;
                    }
                }
            }
        
            button {
                width: auto;
                margin-bottom: 40px;
            }
    }
    
}
.carouselSection{
    .wrapper{
        h2{
            font-size: 3.5rem;
            margin:40px auto;
            text-align: center;
        }
        .facilityCarousel{
            display:flex;
            position: relative;
            align-items: center;
            justify-content: center;
            height:100%;
            margin:40px 0;
            .carouselButton{
                position: absolute;
                width:3.5rem;
                height:3.5rem;
                background-color: rgba(255, 255, 255, 0.75);
                color:$black;
                z-index:20;
                display:flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                &:hover, &:focus{
                    cursor:pointer;
                    transform: translateY(0em);
                    box-shadow: 0 0em 0em -0em $white;
                    background-color: $white;
                }
            }
            .buttonPrevious{
                left:1rem;
            }
            .buttonNext{
                right:1rem;
            }
            .carouselImage{
                display:flex;
                flex-wrap: nowrap;
                overflow-x: scroll;
                justify-content: space-between;
                scroll-behavior: smooth;
                .slide{
                    border-radius: 0px;
                    width:500px;
                    margin-right: 30px;
                    scroll-snap-type: x mandatory;
                }
                .lastSlide{
                    margin-right: 0px;
                }
            }
            
        }
    }
}


@media(max-width: 900px) {

    .aboutSection .wrapper ul {
            width: 90%;
    
            li p {
                font-size: 1.25rem;
            }
        }
    
        .aboutHeading {
            flex-direction: column;
            width: 80%;
    
            .logoName {
                width: 100%;
            }
    
            h3 {
                width: 100%;
                text-align: center;
            }
        }

    .discoverContent {
        flex-direction: column;

        .flexContainer {
            margin-top: 80px;
        }

        .signUpText {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0;
            padding: 20px 0 60px 0;
            width: 80%;
            h3{
                width:100%;
            }
            p {
                text-align: center;
                padding: 0;
                width: 100%
            }
        }
    }
}

@media(max-width: 750px) {
    .specialsSection {
            .wrapper {
                h2 {
                    font-size: 3rem;
                }
            }
        }
        .aboutSection .wrapper {
            padding: 20px 0;
        }

                .bookACall {
                    .wrapper div {
                        h2 {
                            font-size: 3rem;
                            text-align: center;
                        }
                }
            }
        

        .carouselImage {
            .slide {
                width: 100%;
            }
        }
        .carouselButton{
            display: none;
        }

        .carouselSection {
            .wrapper {
                .facilityCarousel {
                    .carouselImage {
                        scroll-snap-type: x mandatory;
                        .slide{
                            width:100vw;
                            margin-right: 10px;
                            scroll-snap-align: center;
                        }
                    }
                }
            }
        }

                .facilityFeaturesSection {
                    background-color: $black;
                    color: $white;
                    .wrapper {
                        width: 90%;
                        h2 {
                            width: 100%;
                            line-height: 1.15;
                            font-size: 2.5rem;
                            text-wrap: wrap;
                            text-align: center;
                        }
                        p{
                            text-align: center;
                        }
                        .facilityFeatureList {
                            padding: 20px 0;
                            li {
                                .graphic {
                                    width: 5rem;
                                }
                                h3{
                                    font-size: 1.75rem;
                                }
                            }
                        }
                    }
        
                }
}
