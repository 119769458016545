@import "../variables";

@media(max-width: 960px) {

    .forFitnessHeader {
        p{
            line-height: 1.25;
        }
        .navContainer {
            background-color: $white;
        }

        .navLinks.active {
            background-color: $white;
        }
    }
}
//end of Nav styles

.fitnessHeaderContent {
    position: relative;
    height: calc(100vh - 90px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../assets/blogAssets/blogImg1.png");
    background-size: cover;
    background-position: top;
    padding-left: 40px;
    &::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: rgba(0,0,0,0.6);
    }
}

.forFitnessHeader{
    display: flex;
    position: relative;
    flex-direction: column;
    width:auto;
        h1 {
            text-align: left;
            line-height: 1;
            margin-bottom: 20px;
            color: white;
            font-size: 4.5rem;
        }
        p{
            color: white;
            font-size: 1.5rem;
            padding-bottom: 10px;
            width:60%;
        } 
    }
    
    .beYourOwnBoss{
        height:100%;
        padding: 0 20px 60px 20px;
    }
    .bossTitle{
        padding: 60px 0;
        font-size: 4rem;
        font-family: $sublineMontserrat;
        h2{
            font-weight: 400;
            line-height: 0.9;
        }
        
        .bossTitleImportant{
            display:flex;
            align-items: center;
            p{
                color:$blue;
                font-size: 5.5rem;
            }
            .logoName{
                width:400px;
                height:90px;
            }
        }
    }

    .fitnessImgContainer{
        display:flex;
        margin: 0 20px;
        height:100%;
        padding-bottom: 40px;
        gap:40px;
        li{
            height:100%;
            width: calc(100% / 3);
            a {
                    text-decoration: none;
                    color:$black;
                    
                }
                p{
                    text-align: center;
                    font-size: 1.25rem;
                }
                h3{
                    font-weight: 600;
                    font-size: 1.5rem;
                    margin-bottom: 10px;
                    font-family: $sublineIBM;
                }
            }
        }
        
        .fitnessImageContainer{
            position: relative;
            margin-bottom: 20px;
            padding:0;
            height:100%;
            width:100%;
            line-height: 0;
            img{
                height: 100%;
                width: 100%;
                border-radius: 0;
                padding:0;
                margin:0;
            }
            .overlay {
                position: absolute;
                bottom: 0;
                background: rgba(0, 0, 0, 0.5);
                width: 100%;
                height:100%;
                transition: .5s ease;
                opacity: 0;
                &:hover, &:focus{
                    opacity: 1;
                }
            }
        }

        .signUpToday{
        background-color: $black;
        color:$white;
        height:100%;
        .wrapper{
            display:flex;
            min-height:100vh;
            flex-direction:column;
            justify-content: center;
            align-items: center;
            padding: 50px 0;
            p{
                width:43%;
                margin-bottom: 20px;
                text-align: center;
            }
            .disclaimerText{
                font-size: 0.8rem;
                text-align: left;
                margin-top: 10px;
            }
        }
    }
        .signUpTodayBlog {
            height: 100%;
            .wrapper {
                min-height: 50vh;
                p{
                    width: 80%;
                }
            }
        }

    .fitnessSectionTitle{
        font-size: 3.5rem;
        font-family: $sublineMontserrat;
        font-weight: 400;
        margin-bottom: 40px;
        .importantHeader{
            color:$blue;
            font-weight: 600;
            text-transform: uppercase;
        }
    }
    
    .resourceSection .wrapper{
        display:flex;
        flex-direction: column;
        align-items: center;
        padding:20px 0;

    }
    .resourceGuide li p{
        text-align: left;
    }

    .areYouReadySection{
        background-color: $black;
        color:$white;
        height:100%;
        .wrapper{
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height:80vh;
            h2{
                text-transform: none;
                font-weight: 400;
                padding-bottom: 80px;
                font-size: 3.75rem;
            }
        }
    }
    
    .areYouReadyContainer{
        width:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .areYouReadyText{
            display: flex;
            align-items: center;
            padding-bottom: 20px;
            .logoName{
                width:350px;
                height:80px;
            }
            p{
                font-size: 2rem;
            }
        }
        button{
            width:auto;
        }
    }

    @media(max-width:950px){
        .fitnessHeaderContent{
            padding:0;
            height:100vh;
            background-position:center;
        }
        
        .forFitnessHeader{
            align-items: center;
            h1{
                font-size: 3.5rem;
                text-align: center;
            }
            p{
                width:90%;
                text-align: center;
            }
        }

        .fitnessSectionTitle {
            font-size: 3rem;
            text-align: center;
            .importantHeader {
                font-size: 3.5;
            }
        }

        .bossTitle{
            font-size: 3.5rem;
            h2{
                text-align: center;
            }
            .bossTitleImportant {
                justify-content: center;
                    p {
                        font-size: 4.5rem;
                    }
            
                    .logoName {
                        width: 250px;
                        height: 60px;
                    }
                }
        }

        .fitnessImgContainer{
            flex-direction: column;
            padding:0 10px;
            li{
                width:100%;
            }
        }
        .signUpToday{
            .wrapper{
                p{
                    width:90%;
                }
            }
        }

        .areYouReadySection {
            .wrapper {
                h2 {
                    text-align: center;
                    font-size: 3.5rem;
                }
            }
        }

                .areYouReadyContainer {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
        
                    .areYouReadyText {
                        .logoName {
                            width: 250px;
                            height: 80px;
                        }
        
                        p {
                            font-size: 1.75rem;
                        }
                    }
        
                    
                }
    }


    @media(max-width:600px){
        .bossTitle{
            font-size: 2.25rem;
            text-align: center;
            .bossTitleImportant{
                p{
                    font-size: 2.5rem;
                }
            }
        }

                .fitnessSectionTitle {
                    font-size: 2.5rem;
                    text-align: center;
        
                    .importantHeader {
                        font-size: 2rem;
                    }
                }

        .fitnessImgContainer {
            padding:0;
            li {
                width: 100%;
                text-align: center;
                p{
                    text-align: center;
                }
            }
        }

        .areYouReadySection h2{
            text-align: center;
            font-size: 5rem;
        }

        .areYouReadySection {
            padding:0 10px;
            .wrapper {
                h2 {
                    font-size: 3.25rem;
                }
            }
        }

        .areYouReadyContainer {
            .areYouReadyText {
                flex-direction: column;

                .logoName {
                    width: 280px;
                    height: 80px;
                }

                p {
                    font-size: 1.25rem;
                }
            }


        }
    }

