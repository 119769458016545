@import "../variables";

footer{
    background-color: $white;
    p, a{
        color: $black;
        text-decoration: none;
        font-size: 1rem;
    }
    .wrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        padding: 20px 0;
        .footerInternalLinks{
            .logoContainer{
                margin:0;
                padding:0;
                height:60px;
            }
        }
        .contactLinks{
            text-align:left;
            .socials{
                display: flex;
                width:350px;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
                li a{
                    display: block;
                    font-size:2.5rem;
                    transition: 0.3s;
                    border-radius: 50%;
                    &:hover, &:focus{
                        color: $blue;
                    }
                }
                .tiktokIcon{
                    font-size: 2.3rem;
                }
            }
        
        }
    }
}

.footerContent{
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    width:100%;
    padding: 0 10px;
    h4{
        color:$grey;
        padding-bottom: 10px;
        border-bottom: 2px solid black;
        margin:20px 0;
        font-size: 1.75rem;
        width:100%;
    }
}


.footerLinks{
    li, li a{
        font-family: $textfontMontserrat;
        margin-bottom: 20px;
        font-weight: bold;
        text-decoration: none;
        transition: 0.3s;
             &:hover,   &:focus{
               color:$blue; 
            }

    }
    
}
.emailLinks{
    display:flex;
    flex-direction: column;
    a{
        font-family: $textfontMontserrat;
            margin-bottom: 20px;
            text-decoration: none;
    }
}

@media(max-width:950px){
    .footerLogo{
        display: none;
    }
}

@media(max-width:920px){
    footer .wrapper{
        flex-direction: column;
        align-items: center;
        width:90%;
        .contactLinks{
            width: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
        }
        .footerInternalLinks .logoContainer{
            height:60px;
            width:200px;
        }
    }


    .footerContent {
        flex-direction: column;
        align-items: center;
        width:80%;
    }
     .footerInternalLinks, .emailLinks, .contactLinks{
            width:100%;
            text-align: center;
    }

}

@media(max-width:650px) {
   footer .wrapper{
       .footerInternalLinks{
           flex-direction: column;
           justify-content: center;
           align-items: center;
           width:90%;
           .footerLinks{
                width:100%;
                text-align: center;
           }
           .logoContainer{
            margin-bottom: 20px;
           }
       }
       .contactLinks{
           flex-direction: column;
           width: 100%;
           .socials{
               width:100%;
               li a{
                   font-size: 2.25rem;
               }
           }
       }
    
   }
   .footerContent{
        width:95%;
   }


    .footerEmail{
        padding-top: 20px;
    }

    .copyright{
        padding: 10px 0;
    }
}


