@import "../variables.scss";

.blogText{
    width:80%;
    padding-top: 100px;
    margin:0 auto;
    .blogImg{
        width:100%;
        margin: 40px 0 20px 0;
    }
    .jennyImg {
        width: 700px;
        object-position: center;
    }
    h2{
        font-size: 2.5rem;
        width:70%;
        margin-bottom: 20px;
    }
    h3{
        text-transform: none;
        font-weight:700;
        font-size: 1.75rem;
    }
    p{
        line-height: 1.5;
        margin: 25px 0;
        font-size: 1.2rem;
    }
    .blogQuoteText {
        font-weight: 700;
        font-size: 1.75rem;
        padding: 0 75px;
    }
    .tab{
        margin-right: 80px;
    }
}

.blogPostHeaderSpan{
    display:inline-block;
}

.authorContainer{
    h4{
        font-size: 1.5rem;
        margin:0 0 10px 0;
    }
    p{
        margin:0;
        color:$grey;
        line-height: normal;
    }
}

.blogPostDiscover{
    margin-top:10px;
    min-height:80vh;
    display:flex;
    .discoverContent{
        display:flex;
        margin-top: 80px;
    }
}

.blogArrowContainer{
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.blogArrow{
    a{
        display: flex;
        align-items: center;
        color:$black;
        text-decoration: none;
    }
    p{
        font-size: 1.75rem;
        padding: 4px 0;
        text-transform: none;
        border-bottom: 4px solid $white;
        &:hover, &:focus{
            border-bottom: 4px solid $black;
        }
    }

    .blogArrowIcon{
        color:$black;
        padding-bottom: 4px;
        font-size: 3rem;
    }
}


.blogArrowNext p{
    margin-left: 20px;
}

.blogArrowLast{
    a{
        flex-direction: row-reverse;
    }
    p{
        margin-right: 20px;
    }
}

.blogContentGuide {
    width:80%;
    margin:0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    button{
        width:auto;
        margin-bottom: 60px;
    }
}

.blogSubTitle{
    margin-bottom: 25px;
}

.specialBlog{
    margin:0 auto;
    width:90%;
    h2{
        margin:0 auto;
        margin-bottom: 20px;
        text-align: center;
    }
    h3{
        text-align: center;
    }
    .authorContainer{
        text-align: center;
        margin-bottom: 20px;
    }
    .blogH3{
        text-align: left;
    }
}

.blogImgType2{
    width:66%;
    margin-left:17%;
}
.infoGraphic2{
    width:44%;
    margin-left:28%;
}

.blogButton{
    width:350px;
    margin-left: calc((100% - 350px) / 2);
    margin-bottom: 60px;
}
.subTitleH3{
    margin-bottom: 20px;
}

.specialList{
    p{
        margin-bottom: 0;
    }
    .listStyle1{
        p{
            margin-top: 0;
        }
    }
}



.blogList {
    margin-left:12%;
    li {
        list-style-type: disc;
        width:76%;
    }
}

.numberedList li {
    list-style-type: decimal;
    &::marker{
        font-weight: bold;
    }
}

.platformList li{
    line-height: 1.5;
    font-size: 1.2rem;
    font-family: $textfontMontserrat;
    margin-bottom: 8px;
}
    
@media(max-width: 950px) {
    .blogPost{
        .navContainer {
            background-color: $white;
        }
    
        .navLinks.active {
            background-color: $white;
        }
    }
}

@media(max-width: 900px){
    .blogText {
        h2{
            width:100%;
        }
        .adrienneImg{
            width:80%;
        }
    }

    .blogArrowContainer {
        flex-direction: column;
    }

        .blogArrowNext {
            align-self: flex-start;
        }
    
        .blogArrowLast {
            align-self: flex-end;
        }

.blogImgType2{
    width:90%;
    margin-left: 5%;
}

.blogList {
    margin-left: 10%;

    li {
        width: 80%;
    }
}
    
    
}
@media(max-width: 600px) {
    .blogText{
        h2{
            font-size: 2.25rem;
        }
        .blogQuoteText {
            font-size: 1.5rem;
            padding:0 20px;
        }
        .tab{
            margin-right: 20px;
        }
    }
        .blogImgType2 {
            width: 98%;
            margin-left: 1%;
        }

          .blogList {
              margin-left: 2%;
    
              li {
                  width: 96%;
              }
          }
}

