@import "../variables";

@media(max-width: 960px) {

    .contentGuideHeader {
        p {
            line-height: 1.25;
        }

        .navContainer {
            background-color: $white;
        }

        .navLinks.active {
            background-color: $white;
        }
    }
}

//end of Nav styles


.contentGuideHeaderContent {
    position: relative;
    height: calc(100vh - 90px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../assets/heroImages/pltfrmImage8.jpg");
    background-size: cover;
    background-position: top;
    padding-left: 40px;
    &::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: rgba(0, 0, 0, 0.6);
    }
}

.contentGuideHeader {
    display: flex;
    position: relative;
    flex-direction: column;
    width: auto;
    h1 {
        text-align: left;
        line-height: 1;
        margin-bottom: 20px;
        color: white;
        font-size: 4rem;
    }

    h3 {
        color: white;
        font-size: 2rem;
        font-weight: 700;
        padding-bottom: 10px;
        width: 60%;
    }

}

.buttonContainer {
    width: 510px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button {
        width: auto;
        padding: 10px 5px;
    }
}

.contentGuideBannerText{
    h2{
        text-transform: uppercase;
        font-family: $sublineMontserrat;
        font-size: 2rem;
        text-align: center;
        padding:40px 0;
    }
}
.importantText{
    color: $blue
}

.whatsIncluded{
    background-color: $black;
    color:$white;
    font-family: $sublineMontserrat;
    .wrapper{
        display:flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 40px;
        ul li{
            padding:0 0 30px 0;
            font-weight: bold;
            font-size: 1.5rem;
            text-align: center;
        }
    }
    h3{
        font-weight: bold;
        font-size: 2.75rem;
        text-transform: none;
        color:$grey;
        padding:40px 0;
        text-align: center;
    }
}

.contentTipsCalendar .wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
    h3{
        font-family: $sublineMontserrat;
        text-transform: none;
        font-weight: 600;
        font-size: 2.25rem;
    }
    
    button{
        width:280px;
    }
    .contentGuideContent{
        display:flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 0;
        div{
            width:38%;
            height:100%;
            h3{
                width:380px;
            }
        }
        .contentTipsImg{
           width:62%;
           padding-right: 80px;
           border-radius: 0;
        }

        .contentCalendarImg {
            width:62%;
            border-radius: 0;
           
        }
    }
}

.contentSignUp .wrapper{
    min-height: 60vh;
    text-align: center;
    p{
        padding-bottom: 40px;
        width:450px;
    }
    .disclaimerText{
        text-align: center;
    }

}

.contentBlogSection .wrapper{
    display:flex;
    flex-direction: column;
    align-items: center;
    h3{
        font-family: $sublineMontserrat;
        text-transform: none;
        font-weight: 600;
        font-size: 2.25rem;
        width:400px;
        padding:40px 0;
        .importantText{
            text-transform: uppercase;
        }
    }
    .blogFlexContainer{
        display: flex;
        gap:60px;
        .blogContainer{
            display:flex;
            flex-direction: column;
            align-items: center;
            p{
                width:95%;
                font-size: 1.5rem;
                text-align: center;
                padding: 10px 0 20px 0;
            }
            .canfitproText{
                width:100%;
            }
            button{
                text-transform: lowercase;
            }
        }
    }
}

@media(max-width:980px){
    .contentGuideHeaderContent{
        padding:0;
        align-items: center;
        height: 100vh;
    }
    .contentGuideHeader{
        align-items: center;
        text-align: center;
        h1{
            width:95%;
            text-align: center;
        }
    }

    .whatsIncluded {
            .wrapper {
                padding:0 10px;

            }
                
    }
    .buttonContainer{
        width:450px;
        button, button a{
            font-size: 1.25rem;

        }
    }

    .contentTipsCalendar .wrapper{
        .contentGuideContent{
            flex-direction: column;
            div{
              width:90%;
              text-align: center;
              h3{
                width:100%;
                padding-bottom: 40px;
                
              }
            }
            .contentTipsImg {
                width: 90%;
                margin:0;
            }
    
            .contentCalendarImg {
                width: 90%;
                margin:0;
            }
        }
        .contentGuideContent1{
            flex-direction: column-reverse;
        }
    }

    .contentBlogSection .wrapper{
        .blogFlexContainer{
            gap:40px;
        }

    }
    
}

@media(max-width:600px){
    .buttonContainer{
        flex-direction: column;
        width:100%;
    }

        .contentSignUp .wrapper {
            p {
                width: 90%;
            }
        }

    .contentBlogSection .wrapper {
        padding:0 10px;
        h3{
            width:90%;
            text-align: center;
            font-size: 2rem;
        }
    .blogFlexContainer {
        flex-direction: column;
    }

}
}
