@import "../variables";

.formSection{
    background-color: $black;
    color: $white;
    height:100%;
    .wrapper{
        min-height:500px;
        display:flex;
        justify-content: space-between;
        align-items: center;
        width:90%;
        padding:50px 0;
        height:100%;
        min-height: 100vh;
    }
}

.signUpForm{
    width:50%;
    height:100%;
    min-height: calc((100vh) - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    .formImage{
        position:absolute;
        top:0;
        left:0;
        object-fit:cover;
        height:100%;
        border-radius: 0;
        opacity: 1;
        padding-right: 20px;
    }
    .signUpText{
        padding: 0 40px 0 10px;
        z-index: 10;
        display:flex;
        flex-direction: column;
        height:100%;
        p{
            width:90%;
            opacity: 1;
            font-size: 1.5rem;
            font-weight: 400;
        }
    }
}

.formContainer{
    width:50%;
}

.signUpHeader{
    h3{
        display:inline-block;
        padding:0 0 10px 0;
        text-transform: none;
        text-align: start;
        font-size: 2.75rem;
        margin-bottom: 20px;
    }
    .logoName{
        height: 40px;
        width:150px;
        display:inline-block;
        background-position: bottom;
        position: relative;
        top:6px;
    }
}


._x53188859 ._form-title {
    font-size: 2.75rem;
    padding: 20px 0 40px 0;
    width: 200px;
    border-bottom: 1px solid white
}

label{
    font-size: 2rem;
}

._x08748932 input {
    margin-bottom: 20px
}

@media(max-width: 900px) {
    .formSection .wrapper{
        flex-direction: column;
        align-items: center;
    }
    .signUpForm{
        width:80%;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .signUpHeader{
            text-align: center;
        }
        .formImage{
            padding:0;
        }
    }

    .formContainer{
        width:70%;
    }
}

@media(max-width:780px) {
   .signUpForm, .formContainer{
    width:90%;
    text-align: center;
   }
   .formContainer{
    padding-bottom: 20px;
   }
   .signUpForm .signUpText{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin:0;
    text-align: center;
    .signUpHeader{
        padding:0 10px;
        h3{
            font-size: 2.25rem;
            text-align: center;
        }
    }
    p{
        padding:0;
        margin:0;
        font-size: 1.25rem;
    }
   }
}
