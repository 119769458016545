@import "../variables.scss";


.blogVideoSection{
    min-height: 90vh;
    padding-bottom: 20px;
    .blogContainer1{
        display:flex;
        justify-content: space-between;
        height: auto;
        margin-top: 90px;
        .blogThumbContainer{
            width:70%;
            height:auto;
            video{
                max-height: 100%;
                max-width: 100%;
                height:100%;
                width:100%;
                object-fit: contain;
            }
        }

        .blogPostDetails{
            width:35%;
            height:auto;
            padding:20px 0 0 15px;
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            p{
                font-size:1.15rem;
            }
            .postData{
                display: flex;
                justify-content: space-between;
                p:nth-child(2){
                    color:$grey;
                }
            }
            .authorTag{
                font-weight: 700;
            }
            h4{
                font-size: 1.8rem;
                margin:15px 0;
            }
            button{
                margin: 20px 0;
                a{
                    color: $white;
                    font-size: 1.25rem;
                    font-weight: 600;
                    font-family: $sublineIBM;
                    text-decoration: none;
                }
            }
        }


    }
}

.linkBlock{
    display:inline-block;
}


.followUs{
    background-image: url("../assets/blogAssets/blogBackground1.png");
    background-position: center;
    background-size: cover;
    height:80vh;
    display: flex;
    flex-direction: column;
    padding:90px 0 0 40px;
    .followContent{
        width:50%;
        h2{
            font-size: 6rem;
            font-weight: 500;
            margin-bottom: 20px;
        }
        p{
            font-size: 2.5rem;
            font-weight: 700;
            margin-bottom: 10px;
        }

        .socials {
            display: flex;
            width: 350px;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            li a {
                display: block;
                font-size: 2.5rem;
                color:black;
                transition: 0.3s;
                border-radius: 50%;
                &:hover,
                &:focus {
                    color: $blue;
                }
            }

            .tiktokIcon {
                font-size: 2.3rem;
            }
        }
    }
}

@media(max-width: 980px){
    .blogVideoSection .blogContainer1{
        flex-direction: column;
        align-items: center;
        width:100%;
        .blogPostDetails{
            width:95%;
        }
        .blogVideoContainer{
            width:95%;
        }
    }
    
    .followUs {
        background-position: bottom;
        background-size: contain;
        background-repeat: no-repeat;
        .followContent{
            width:90%;
            h2{
                font-size: 5rem;
            }
            p{
                font-size: 2.25rem;
            }
            .socials {
                width:260px;
                li a{
                    font-size: 2rem;
                }
                .tiktokIcon{
                    font-size: 2.125rem;
                }
    
            }
        }
    }
}

@media(max-width: 960px) {
    
    .blogVideoSection{
        min-height:70vh;
    }
    .followUs{
        .followContent{
            h2{
                font-size: 4rem;
            }
        }
    
    }
    .blogSection{
        .navContainer {
            background-color: $white;
        }
    
        .navLinks.active {
            background-color: $white;
        }
    }
}

@media(max-width:480px ){
    .followUs{
        padding:30px 0 0 0;
        height:65vh;
        .followContent{
            margin: 0 auto;
            text-align: center;
            // padding-bottom: 20px;
            p{
                font-size: 1.75rem;
            }
            .socials{
                margin:0 auto;
           }
        
    }
    }
}
