@import "../variables";

@media(max-width: 960px) {

    .trainerTemplateHeader {
        p {
            line-height: 1.25;
        }

        .navContainer {
            background-color: $white;
        }

        .navLinks.active {
            background-color: $white;
        }
    }
}

//end of Nav styles

.trainerHeader {
    position: relative;
    height: calc(100vh - 90px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: top;
    padding-left: 40px;
    .wrapper {
        .trainerHeaderText {
            color: $white;
            h1 {
                text-align: left;
                line-height: 1;
                margin-bottom: 20px;
                font-size: 5rem;
            }

            p {
                font-size: 1.5rem;
                padding-bottom: 40px;
                text-transform: uppercase;
                font-weight: bold;
            }

            .trainerContactButton{
                width:auto;
            }             
        }
    }
}
//trainer profile specific images
.kareemHeader{
    background-image: url("../assets/trainerAssests/kareem/kareemHero.png");
}
.tishaHeader{
    background-image: url("../assets/trainerAssests/tisha/tishaHero.png");
}
.danielaHeader {
    background-image: url("../assets/trainerAssests/daniela/danielaHero.png");
}
.kdHeader {
    background-image: url("../assets/trainerAssests/kd/kdHero.png");
}

.getToKnow{
    .wrapper{
        padding:0 100px;
        h2 {
                text-transform: uppercase;
                padding: 80px 0 40px 0;
            }
            .trainerBio {
                display: flex;
                justify-content: space-between;
                position: relative;
                padding: 20px 0;
        
                .trainerQuotes {
                    object-position: bottom;
                    width: 25%;
                    object-fit: contain;
                    position: absolute;
                    top: -100px;
                    left: -10px;
                }
        
                .bioText {
                    width: 100%;
                    padding-left: 350px;
        
                    p {
                        margin-bottom: 20px;
                    }
                }
            }
    }
    
}
.trainerVideo{
    .wrapper{
        display:flex;
        justify-content: center;
        padding:20px 0;
        video{
            max-height: 80vh;
        }
    }
}
.qualifications{
    background-color: $blue;
    color:$white;
    .wrapper{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:80px 0;
        h2{
            text-transform: uppercase;
            font-size: 2.75rem;
            padding-bottom: 50px;
        }p{
            padding-bottom: 10px;
            text-align: center;
        }
    }
}

.quotes{
    .wrapper{
        display:grid;
        padding:40px 100px;
        .quoteContainer{
            width:50%;
            text-align: center;
            padding: 60px 0;
            h3{
                text-transform: none;
                font-weight: bold;
                padding-bottom: 10px;
            }
        }
        .quote2{
            justify-self: right;
        }
        .singleQuote{
            margin:0 auto;
        }
    }
}

.trainerSocials{
    background-color: $black;
    color:$white;
    .wrapper{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .h2FormLink {
                text-transform: uppercase;
                padding: 80px 0 40px 0;
                text-decoration: none;
                a{
                    color: $white;
                    text-decoration: none;
                    transition: 0.3s;
                    &:hover,
                        &:focus {
                            color: $blue;
                        }
                }
            }
        .trainerSocialsContainer{
            display:flex;
            align-items: center;
            padding:40px 0;
            justify-content: center;
            .iconCircle {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 4rem;
                    transition: 0.3s;
                    border-radius: 50%;
                    color:$white;
                    &:hover,
                    &:focus {
                        color: $blue;
                    }
                }
                .tiktokIcon {
                    font-size: 3.4rem;
                    
                }
        }
    }
}


@media(max-width:980px) {
    .trainerHeader {
        padding: 0;
        align-items: center;
        height: 100vh;
        .wrapper {
            .trainerHeaderText {
                display:flex;
                flex-direction: column;
                align-items: center;
                h1 {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }

    .getToKnow {
        .wrapper{
            padding:80px 0;
            h2 {
                    text-align: center;
                }
                .trainerBio {
                    .trainerQuotes {
                        display: none;
                    }
            
                    .bioText {
                        width: 95%;
                        padding-left: 0;
                        margin: 0 auto;
            
                        p {
                            margin-bottom: 20px;
                        }
                    }
                }
        }

    }

        .trainerVideo {
            .wrapper {
                width: 95%;
                video {
                    width: 100%;
                    max-height:none;
                }
            }
        }

    .quotes {
        .wrapper {
            padding:40px 50px;
            .quoteContainer {
                width: 100%;
                text-align: center;
                padding: 40px 0;
                h3 {
                    text-transform: none;
                    font-weight: bold;
                    padding-bottom: 10px;
                }
            }
            .quote2 {
                justify-self: right;
            }
        }
    }

}

@media(max-width:600px) {
    .trainerHeader {
        .wrapper {
            width: 95%;
            .trainerHeaderText {
                h1 {
                    font-size: 3.5rem;
                }
                p {
                    width: auto;
                }
            }
        }
    }
    .trainerVideo {
        .wrapper {
            width:98%;
            video {
                width:100%;
            }
        }
    }
}
