// fonts
$headlineIBM: "IBM PLEX SANS CONDENSED", "sans-serif";
$sublineIBM: "IBM PLEX SANS", "sans-serif";
$textfontIBM: "IBM PLEX SANS", "sans-serif";

$headlineMontserrat: "Montserrat", "sans-serif";
$sublineMontserrat: "Montserrat", "sans-serif";
$textfontMontserrat: "Montserrat", "sans-serif";


// colours
$black: #000000;
$white: #FFFFFF;
$blue: #09A8FA;
$grey: #7F7F7F;
$darkGrey: #6D6D6D;
$offWhite: #E9E6DF;