@import "../variables";

.navContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    left: 0;
}

.logoContainer {
    display: flex;
    height: 80px;
    width: 200px;
    .logo3 {
        margin-right: 10px;
        height: 80px;
        width: 200px;
    }

}
.navLinks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 700px;
    text-transform: capitalize;
    .siteNav {
        margin-left: 0 auto;
        padding: 10px;
        color:$black;
        font-size: 1.2rem;
        font-weight: 600;
        font-family: $sublineIBM;
        transition: 0.5s;
        border-radius: 15px;
        text-decoration: none;
        a{
            text-decoration: none;
            color: black;
        }
        &:hover,
        &:focus {
            background-color: $black;
            padding: 10px;
            a{
                color:$white;
            }
        }
    }

    .contactUs {
        background-color: $blue;
        text-transform: uppercase;
        text-align: center;
        padding: 10px;
        text-wrap: wrap;
        width: 8rem;
    }
}

.menuIcon {
    display: none;
    color: black;
    z-index: 20;
    height: 20px;
    width: 20px;
    transition: 0.3s;
    font-size:1.5rem;
    &:hover,
    &:focus {
        color: $blue;
    }
}

._form_18{
    position:absolute;
}
@media(max-width: 960px) {
    .navContainer {
        position: fixed;
        background-color: $black;
        z-index: 20;
        padding-right: 20px;
        justify-content: space-between;
        margin: 0;
        height: 70px;
    }

    .logoContainer .logo3 {
        width:180px;
    }
    
    .menuIcon {
        display: block;
    }

    .navLinks {
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        height: 280px;
        width: auto;
        position: absolute;
        left: -100%;
        top: 70px;
        transition: 0.5s ease;
        .siteNav{
            padding:10px;
        }
        .contactUs {
            width: auto;
        }
    }

    .navLinks.active {
        position: fixed;
        left: -10px;
        transition: 0.5s ease;
        background-color: $black;
        left: 0;
    }
}