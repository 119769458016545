@import "../variables";

.headerContent{
    background-image: url(../assets/heroImages/pltfrmImage5.png);
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    height:calc(100vh - 90px);
    padding-left: 40px;
    .wrapper {
        width:100%;
        height:100%;
        display:flex;
        align-items: center;

    }
    .aboutUsHeader{
        display:flex;
        padding-top: 60px;
        flex-direction: column;
        width:530px;
        h2{
            text-align: left;
            line-height: 1.25;
            margin-bottom: 20px;
            color:white;
            font-size: 2.5rem;
        }
        button{
            padding:10px 10px;
        }
    }
}
.buttonContainer{
    width:480px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    button{
        width:auto;
        padding:10px 5px;
    }
}

.aboutUsPage p {
    margin-bottom: 15px;
    line-height: 1.25;
    font-size: 1.1rem;
}

.aboutUs{
    width:100%;
    .wrapper{
        background-image: url(../assets/background.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display:flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height:85vh;
        .aboutUsContainer{
            display: flex;
            justify-content: space-between;
            .aboutText{
                width: 42%;
                color:white;
                padding-right: 20px;
                h4{
                    font-size:1.75rem;
                    margin:0
                }
                
            }
            .aboutTitle{
                width:400px;
                height: 270px;
                padding:10px 0;
                margin-bottom: 200px;
                h2{
                    font-size: 6.75rem;
                    text-transform: capitalize;
                    font-family: $sublineMontserrat;
                    font-weight: 500;
                    text-align: center;
                }
                .logoName{
                    width:100%;
                    padding:0;
                    height:110px;
                    margin:0 auto;
                }
            }
        }
    }
}

.resourceButton{
    background-color: $grey;
    width:360px;
    margin-top:20px;
    a{
        font-size: 1.5rem;

    }
}

.founderArrowContainer{
    width:240px;
    color:$white;
    display:flex;
    flex-direction: column;
    align-items: center;
    .arrowIcon{
        color:$black;
        margin:0 auto;
        padding:0;
        font-size:1.5rem;
    }
    .arrowContainerText p{
        font-weight: 700;
        font-size: 1.25rem;
        margin:0;
    }
    .arrowHoverContainer{
        height:50px;
        width:50px;
    }
}

.founder{
    background-color: $black;
    color:$white;
    min-height:80vh;
    display:flex;
    align-items: center;
    .wrapper{
        display:flex;
        align-items:center;
        justify-content: space-between;
        height:100%;
        .founderText{
            width:600px;
            padding-left: 20px;
        }
        .founderImg{
            width:500px;
            border-radius: 50%;
        }
    }
}

.visionContainer{
    display:flex;
    align-items: center;
    min-height:60vh;
    .visionText{
        width:50%;
        padding: 0 20px 0 40px;
    }
    img{
        width:50%;
    }
    button{
        width:auto;
        padding:10px 10px;
        margin-bottom:10px;
    }
    .disclaimerText{
        font-size: 0.8rem;
    }
}

@media(max-width:1000px) {
    .aboutUs {
        .wrapper {
            background-size:auto;
            .aboutUsContainer {
                width:98%;
                .aboutTitle {
                    width: 325px;
                    margin-bottom: 0;
                }
                
                .aboutText {
                    margin-top: 40px;
                    width: 50%;
                    text-align: right;
                }
            }
        }
        
    }
    .aboutUsPage p {
        font-size: 1rem;
    }
}


@media(max-width: 960px) {

    .aboutHeader {
        .navContainer {
            background-color: $white;
        }

        .navLinks.active {
            background-color: $white;
        }
    }

    .headerContent{
        height:100vh;
        padding:0;
        .wrapper{
            justify-content: center;
        }
    }
    .aboutUs{
        width:100%;
    }
    .aboutUsContainer{
        flex-direction: column;
        .aboutText{
            align-self: flex-end;
            width:100%;
        }
    }

    .founder .wrapper{
        flex-direction: column-reverse;
        min-height:100vh;
        .founderText{
            margin-bottom: 20px;
            width:80%;
            padding: 0;
        }
        .founderImg{
            width:80%;
        }
    }

    .visionContainer{
        flex-direction: column;
        align-items: center;
        padding:20px 0;
        .visionText{
            width:90%;
            padding:0;
            p{
                margin-top: 20px;
            }
        }
        img{
            width:90%;
        }
    }
    .resourceButton {
        width:auto;
    }
}

@media(max-width:670px){
    .headerContent {
            .aboutUsHeader{
                width:90%;
                h2{
                    text-align: center;
                }
            }
        }

   .aboutUs .wrapper{
        background-image:none;
        background:linear-gradient(to bottom,white 0%, white 270px, $blue 270px);
        align-items: center;
       .aboutUsContainer{
           width:90%;
           margin:0 auto;
           align-items: center;
           padding-bottom: 20px;
           .aboutText{
               width:100%;
               text-align:center;
           }
       }

   }
   .visionContainer .visionText{
        display:flex;
        flex-direction: column;
        align-items: center;
        button{
            width:300px;
        }
   }
        
    

    .founder .wrapper {
            .founderText {
                width: 95%;
                .founderImg {
                    width: 90%;
                }
            }
            
        }
        .buttonContainer{
            display: flex;
            flex-direction: column;
            align-items: center;
            width:100%;
            button{
                width:290px;
                margin:10px;
            }
        }
    }