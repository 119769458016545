@import "../variables";

@media(max-width: 960px) {

    .ourTrainersHeader {
        p {
            line-height: 1.25;
        }

        .navContainer {
            background-color: $white;
        }

        .navLinks.active {
            background-color: $white;
        }
    }
}

//end of Nav styles

.ourTrainersHeaderContent {
    position: relative;
    height: calc(100vh - 90px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../assets/heroImages/membershipsHero.png");
    background-size: cover;
    background-position: top;
    padding-left: 40px;
    .wrapper {
        .ourTrainersHeaderText {
            color: $white;
            h1 {
                text-align: left;
                line-height: 1;
                margin-bottom: 20px;
                color: white;
                font-size: 5rem;
            }

            p {
                color: white;
                font-size: 1.5rem;
                padding-bottom: 10px;
            }

            .buttonContainer {
                width:600px;
                button {
                    padding: 10px 20px;
                }
            }
        }
    }
}

.whyWorkWithATrainer{
    min-height:80vh;
    .wrapper {
        display:flex;
        flex-direction: column;
        justify-content: center;
        height:100%;
        padding:40px 100px;
            h2 {
                padding: 40px 0;
                font-size: 2.5rem;
            }
            p {
                padding-bottom: 30px;
            }
        }
} 


.trainerIconSection{
    background-color: $blue;
    min-height:100vh;
    .wrapper {
            padding:10px 0;
            .findYourTrainer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .trainerIcon {
                    width: 35%;
                }
                h3 {
                    justify-self: center;
                    color:$white;
                    text-align: center;
                    width:60%;
                    text-transform: none;
                    padding:0 40px;
                    box-sizing: border-box;
                }
            }
        }

} 

.howDoYouChooseTheRightTrainer, .needHelpPickingATrainer{
    background-color: $black;
    color:$white;
    .wrapper{
        padding:0 100px;
    h2{
        color:$blue;
        text-transform: uppercase;
        padding: 40px 0;
        font-size: 2.5rem;
    }
    p{
        padding: 20px 0;
    }
    .buttonContainer{
        padding:40px 0;
        button{
            padding:10px 40px;
        }
    }
}}

.trainerProfileSection {
    .wrapper{
        padding:0 100px;
        h2{
            padding:40px 0;
            font-size: 2.5rem;
        }
        .trainerContainer{
            display:flex;
            padding-bottom: 100px;
            justify-content: space-between;
            .trainerProfileImage{
                width:25%;
                border-radius: 0;
            }
            .trainerText{
                padding-left: 100px;
                width:65%;
                height:auto;
                h3{
                    color:$blue;
                    font-weight: bold;
                    text-transform: capitalize;
                    margin-bottom: 20px;
                }
                div{
                    display:flex;
                    flex-direction: column;
                    justify-content: space-between;
                    p{
                        margin-bottom: 14px;
                    }
                }   
                .buttonContainer{
                    width:auto;
                    margin-top: 10px;
                }
            }
        }
    }
}

@media(max-width:980px) {
    .ourTrainersHeaderContent {
        padding: 0;
        align-items: center;
        height: 100vh;
        .wrapper{
            .ourTrainersHeaderText {
                h1 {
                    width: 95%;
                    margin:0 auto;
                    text-align: center;
                }
                p{
                    text-align: center;
                }
                .buttonContainer {
                    margin:0 auto;
                }
            }
        } 
    }
    .trainerIconSection {
        min-height:auto;
        .wrapper {
            .findYourTrainer {
                .trainerIcon {
                    width: 50%;
                }

                h3 {
                    width: 50%;
                }
            }
        }

    }

    .trainerProfileSection {
        .wrapper{
            .trainerContainer {
                    flex-direction: column;
                    align-items: center;
            
                    .trainerProfileImage {
                        width: 100%;
                    }
            
                    .trainerText {
                        padding-left: 0;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
            
                        h3 {
                            padding: 20px 0;
                        }
            
                        p {
                            text-align: center;
                        }
                    }
                }
        }
    }

}

@media(max-width:600px) {
    .ourTrainersHeaderContent {
            .wrapper {
                width: 95%;
                .ourTrainersHeaderText {
                    h1{
                        font-size: 3rem;
                    }
                    p{
                        width:auto;
                    }
                    .buttonContainer {
                        width:auto;
                    }
                }
            }
        }
    .whyWorkWithATrainer{
        height:auto;
        .wrapper{
            padding:0;
            h2, p{
                width:95%;
                margin: 0 auto;
                text-align: center;
            }
        }
    }

    .trainerIconSection {
        .wrapper {
            .findYourTrainer {
                flex-direction: column;
                .trainerIcon {
                    width: 95%;
                }
                h3 {
                    width:95%;
                }
            }
            .secondIcon{
                display:flex;
                flex-direction: column-reverse;
                h3{
                    padding-bottom: 20px;
                }
            }
        }

    }

    .howDoYouChooseTheRightTrainer, .needHelpPickingATrainer{
        .wrapper{
            padding:0;
            h2,p{
                margin:0 auto;
                text-align: center;
                width:95%;
            }
        }
    }
    .trainerProfileSection{
        .wrapper{
            padding:0;
        }
    }
}