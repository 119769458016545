@import "../variables";

@media(max-width: 960px) {

    .membershipsHeader {
        p {
            line-height: 1.25;
        }

        .navContainer {
            background-color: $white;
        }

        .navLinks.active {
            background-color: $white;
        }
    }
}

//end of Nav styles

.membershipsHeaderContent {
    position: relative;
    height: calc(100vh - 90px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../assets/heroImages/membershipsHero.png");
    background-size: cover;
    background-position: top;
    padding-left: 40px;
    .wrapper{
        .membershipsHeaderText{
            color: $white;
            h1 {
                    text-align: left;
                    line-height: 1;
                    margin-bottom: 20px;
                    color: white;
                    font-size: 4.5rem;
                }
            
                p {
                    color: white;
                    font-size: 1.5rem;
                    padding-bottom: 10px;
                    width: 60%;
                }
            .buttonContainer{
                button{
                    padding: 10px 20px;
                }
            }
        }
    }
}

.membershipBenefits{
    height:100%;
    min-height: 70vh;
    h2 {
            font-size: 2.5rem;
            font-weight: 600;
            text-align: center;
            padding: 40px 100px;
            span{
                display: inline-block;
                color: $blue;
            }
        }
    .benefitsContainer{
        display:flex;
        justify-content: space-between;
        height: auto;
        li{
            width: 25%;
            .logoBlackCircle{
                height: 10rem;
            }
            p{
                text-align: center;
            }
        }
    }
}

.membershipSection{
    background-color: $black;
    color:$white;
    .wrapper{
        h2{
            font-size: 2.5rem;
            width:100%;
            text-align: center;
            padding:0.75rem 0;
        }
        span{
            display:inline-block;
            color:$blue;
        }
        p{
            width:80%;
            margin:0 auto;
            text-align: center;
            padding-bottom: 2rem;
        }
        h3{
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 0.2rem;
        }
    }
    .option1{
        margin-right: 3rem;
    }
    .membershipOptionTable{
        display: flex;
        justify-content: center;
        margin:0 auto;
        padding-bottom: 80px;
        padding-top: 20px;
        .membershipOption{
            width:30%;
            display: flex;
            flex-direction: column;
            .optionInfo {
                    background-color: $white;
                    color: $black;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    h4 {
                        font-size: 1.4rem;
                        height:8rem;
                        font-weight: 500;
                        margin: 0;
                        background-color: $grey;
                        color:$white;
                        display: flex;
                        align-items: center;
                        padding: 1rem 2rem 1rem 0.5rem;
                    }

                    .membershipCost{
                        width: 100%;
                        margin-top: 20px;
                        color: $blue;
                        border-top: 1.5px solid $blue;
                        border-bottom: 1.5px solid $blue;
                        display: flex;
                        justify-content: center;
                        h3 {
                            font-weight: bold;
                            margin:0;
                        }
                        p{
                            font-weight: bold;
                            font-size: 1rem;
                            width:auto;
                            margin: 0;
                        }
                    }
                    .membershipFeatures{
                        display:flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width:auto;
                        .straightLine, .crossSign{
                            height:4rem;
                            width:4rem;
                            margin:0;
                        }
                        p{
                            width:90%;
                            text-align: left;
                            padding-left: 3rem;
                        }
                    }
                }
                button {
                    margin-bottom: 3rem;
                    text-wrap: nowrap;
                }
        }
    }
    
    .offerText{
        color:$blue;
        p {
            padding:0;
            text-transform: uppercase;
            span{
                font-weight: bold;
            }
        }
        h3{
            font-weight: bold;
            padding-bottom: 20px;
        }
    }
}

.learnMoreAboutMemberships{
    padding: 10px 0 80px 0;
    background-color: $black;
    .wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h3{
            color: $white;
        }
        h2 {
                font-size: 3.5rem;
                text-align: center;
                padding: 10px 0;
                color: $white;
            }
        
            span {
                display: inline-block;
                color: $blue;
            }
        button{
            width:auto;
            margin-bottom: 40px;
        }
        p{
            color:$white;
            width:60%;
            text-align: center;
            margin:30px auto;
        }

    }
}


@media(max-width:960px) {
    .membershipsHeaderContent {
        padding: 0;
        height: 100vh;
        background-position: center;
    }

    .membershipsHeaderText {
        align-items: center;

        h1 {
            font-size: 3.5rem;
            text-align: center;
        }

        p {
            width: 90%;
        }
    }

    .featuresList {
        .wrapper {
            .featuresListContent {
                ul {
                    width: 90%;
                    li {
                        font-size: 1.35rem;
                    }
                }

                button {
                    margin: 20px 0;
                }
            }

        }
    }

        .specialOffer {
            text-align: center;
    
            h3 {
                display: block;
            }
    
            .membershipOffer {
                display:block;
            }
        }

        .membershipSection .membershipOptionTable {
            .membershipOption {
                width: 50%;
            }
            .fillerOption{
                display:none;
            }
            .optionHighlight{
                position: relative;
                left:0;
                top:0;
            }
        }
}


@media(max-width:700px) {
    .membershipsHeaderContent .wrapper{
        .membershipsHeaderText{
            h1{
                text-align: center;
                font-size:3.6rem;
            }
            p{
                text-align: center;
                width:98%;
            }
        }

    }

    .membershipsBannerText {
        h2 {
            padding:40px 5px;
            font-size: 2.25rem;
        }
    }

        .membershipSection{
            .wrapper h2{
                font-size: 2.4rem;
                padding:40px 0;
                margin:0 auto;
            }
            .membershipOptionTable {
                flex-direction: column;
                padding-bottom: 0;
               .membershipOption {
                   width: 96%;
                   margin:0 auto;
                   .optionInfo{
                        h3, h4, div{
                            margin:0 auto;
                            text-align: center;
                        }
                        h4{
                            width:100%;
                            padding:2rem 1rem;
                        }
                   }
                   .membershipFeatures{
                    padding-top: 40px;
                    text-align: center;
                    li{
                        padding-bottom: 20px;
                    }
                   }
               }
               
           }
        }
        .option1{
            margin-right: 0;
        }
                .membershipBenefits {
                    width:96%;
                    h2{
                        padding: 40px 0;
                        font-size: 2.4rem;
                        span{
                            display:inline;
                        }
                    }
                    .benefitsContainer {
                        flex-direction: column;
        
                        li {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            p{
                                text-align: left;
                                width:calc(95% - 10rem);
                            }
                            .logoBlackCircle{
                                width:10rem;
                            }
                        }
                    }
                }
    
    .learnMoreAboutMemberships .wrapper{
        width: 96%;
        h3{
            text-align: center;
            padding:3rem 0;
        }
        p{
            width:96%;
        }
    }
}

